.dx-menu-item-content {
  color: #fff;
}

.dx-menu-item-expanded .dx-menu-item-content,
.dx-submenu .dx-menu-item-text {
  color: #3f51b5 !important;
}

.dx-menu-item-expanded .dx-menu-item-content,
.dx-submenu .dx-icon {
  color: #3f51b5 !important;
}

