* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Roboto';
}

html,
body,
#root {
  height: 100%;
  background-color: rgb(246, 246, 246);
}

.App {
  text-align: center;
}

.AppContainer {
  padding-top: 75px;
}
